<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{},
				count_blog:{},
            }
		},
		computed:{
			myParams(){
				let query = Gen.clone(this.$route.query)
				if(this.$route.query.search) query.search = this.$route.query.search
				return query
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.getApi()
		},
        methods: {
        	getApi() {
				Gen.apirest("/list-news",this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.data = resp.data
					this.count_blog = resp.count_blog
				})
			},
			onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			}
        },
        watch: {
			$route(){
				this.getApi()
			}
		}
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="clearfix">
					<div class="row justify-content-around dark">
						<div class="col-md-12">
							<div class="heading_section">
								<h2>{{ web.mn_news }}</h2>
							</div>							
						</div>
					</div>
					<div class="container">
						<h3 v-if="count_blog<1" style="color:#fff" class="text-center">{{ web.lbl_no_data }}</h3>
						<div v-else class="row">
							<div v-for="(v,k) in data.data" :key="k" class="col-lg-4 col-md-12 col-12" data-aos="fade-up" data-aos-duration="1000">
								<div class="news_wrapper">
									<div class="news_thumb">
										<router-link :to="{name:'DetailNews', params:{slug:v.slug}}">
											<img :src="uploader(v.image)" :alt="v.title" :title="v.title">
										</router-link>
									</div>
									<div class="news_content_post">
										<h3>
											<router-link :to="{name:'DetailNews', params:{slug:v.slug}}">
												{{ v.title }}
											</router-link>
										</h3>
										<p v-html="v.excerpt"></p>
										<ul>
											<li><a href="javascript:;">{{ (v.publish_date||"").dates("format") }}</a></li>
		                                    <li><a href="javascript:;">{{ v.author }}</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Pagination class="justify-content-center mt-5" :data="data" :limit="8" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>